@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');

#imgDisc{
    display:block;
    top:0px;
    width:100%;
    height:100vh;
    object-fit:cover;
    left:0px;
    top:0px;

}
#headerAbout{
    position:relative;
    float:left;
    width:100%;
    height:100%;
}
#headerAbout p{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
    font-size:1.5vw;
    color:white;
    position :absolute;
    top:90%;
    left:50%;
    margin: auto;
    transform: translateX(-50%);
}
#headerAbout span{
    
    font-size:2.5vw;
}
#aboutWrapper{
    padding-top:2.5vw;
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5vw;
  grid-auto-rows: minmax(auto, auto);
  width: 100%;
  
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#aboutWrapper p{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1.25vw;
}
#aboutWrapper p span{
    font-size: 2.5vw;
}
#aboutText{
    margin:5vw;
}
#aboutText p{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1.25vw;
}

@media all and (orientation: portrait){
  #headerAbout p{
    font-size: 6vw;

  }
  #headerAbout span{
    font-size:8vw;
  }
  #aboutText p{
    font-size: 4vw;
  }
  #aboutWrapper p{
    font-size:2.75vw;
  }
  #aboutWrapper p span{
    font-size:6vw;
  }
}