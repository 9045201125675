@import url('https://fonts.googleapis.com/css2?family=Mohave:wght@600&display=swap');
#imgHeader{
    width:100%;
    height:100vh;
    object-fit: cover;
    display:block;
    left:0px;
    top:0px;
}
#imgLogo{
    position:absolute;
    top:60%;
    left:50%;
    width:20vw;
    transform: translate(-50%, -50%);

}
#btnArtistes{
    font-family: 'Mohave', sans-serif;
    width:13.5vw;
    height:2.5vw;
    position:absolute;
    background-color: transparent;
    border-radius: 1.25vw;
    color:white;
    padding: 0.25vw 0.25vw;
    font-size:1.5vw;
    background-repeat: no-repeat;
    border: 0.05vw solid white;
    cursor: pointer;

    top:90%;
    left:50%;
    transform: translateX(-50%);

}
#homeHeader{
    position:relative;
    float:left;
    width:100%;
    height:100%;
}

@media all and (orientation: portrait){
    #imgLogo{
    position:absolute;
    top:50%;
    left:50%;
    width:50vw;
    transform: translate(-50%, -50%);

        
    }


    #btnArtistes{
    width:27vw;
    height:5vw;
    border-radius: 2.5vw;
    padding: 0.5vw 0.5vw;
    font-size:3vw;
    }
}