@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

#prog{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
    font-size:1.75vw;
    margin:2.5vw;
    text-align:left;
    color:white;
    margin-bottom: 7.5vw;
}
#gridProg .namePremierePartie span{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    font-size:1.10vw;

    margin-bottom: 0px; 
}


#gridProg .deuxParties .img img{
  width:10vw;
  height:20vw;
  object-fit: cover;
  margin:0.1vw;
}



#gridProg .deuxParties .img .vl{
    margin:0.05vw;
    margin-top:0.1vw;
    margin-bottom:1vw;
    background-color: white;
    height:20vw;
    width:0.1vw;
    display:inline;
    position: absolute;
    left:10.1vw;
}

#gridProg .deuxParties{
  position: relative;
}






#gridProg .name{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-left: 0.15vw;
    margin-bottom: 0px;
}

#gridProg .namePremierePartie{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  text-align: left;
  margin-left: 0.15vw;
  margin-bottom: 0px;
  font-size: 1.20vw;
}


#gridProg .unePartie .img img{
  width:20vw;
  height:20vw;
  object-fit: cover;
  margin:0.1vw;
}

#gridProg .unePartie{
  position: relative;
}

#gridProg .unePartie .img .date{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    font-size:1.25vw;
    position:absolute;
    top:0;
    left:0;
}

#gridProg .unePartie .img .date span{
    font-size:2.25vw;
}

#gridProg .unePartie,#gridProg .deuxParties{
    top:0px;
    margin-bottom: 5vw;
}






#gridProg .img{
    position:relative;
    width: fit-content;
    margin: auto;
}

#gridProg .deuxParties .img .date{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    font-size:1.25vw;
    position:absolute;
    top:0;
    left:0;
}

#gridProg .deuxParties .img .date span{
    font-size:2.25vw;
}
#gridProg a{
  text-decoration: none;
}
#gridProg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5vw;
  grid-auto-rows: minmax(auto, auto);
  width: 100%;
  
  margin: auto;
  text-align: top;
  align-items: top;
  justify-content: top;
}

#aPropos img{
  width:15vw;
  height:15vw;
  object-fit: cover;
  margin:0.1vw;
}
#aPropos{
  grid-column: 2;
  grid-row: 1;
}
#benevoles img{
  width:15vw;
  height:15vw;
  object-fit: cover;
  margin:0.1vw;
}
#benevoles{
  grid-column: 3;
  grid-row: 1;
}
#gridProg div p{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
    font-size:1.6vw;
    margin:0.5vw;
    text-align:center;
    color:white;
}


#LienButton {
  background: #5E5DF0;
  border-radius: 50vw;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 600;
  font-size: 1.8vw;
  line-height: 1.2vw;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 1.3vw 2vw;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}



@media all and (orientation: portrait){
  #homeContent #prog{
    font-size:3.5vw;
    margin:5vw;
  }
  #gridProg div p{
    font-size:4vw;
    margin:1vw;
  }
  #gridProg .namePremierePartie span{
    font-size:2vw;
    margin:1vw;
  }
  #gridProg .name{
    margin-left:0vw;
  }

  #gridProg .namePremierePartie{
    font-size: 3vw;
  }
  #gridProg .unePartie .img img{
    width: 24vw;
    height:24vw;
  }

  #gridProg .deuxParties .img img{
    width:12vw;
    height:24vw;
  }
  
  
  
  #gridProg .deuxParties .img .vl{
      height:24vw;
      width:0.4vw;
      left:12vw;
  }
  #gridProg{
    grid-template-columns: repeat(2, 1fr);
  }#gridProg .unePartie .img img{
    height:35vw;
    width:35vw;
  }#gridProg .deuxParties .img img{
    height:35vw;
    width:17.5vw;
  }#gridProg .deuxParties .img .vl{
      
    height:35vw;
    left:17.5vw;  
  
  }#gridProg .deuxParties .img .date,#gridProg .unePartie .img .date{
      
    font-size:4vw; 

  }#gridProg .deuxParties .img .date span,#gridProg .unePartie .img .date span{
      
  font-size:6vw; 

  }#gridProg .deuxParties .img .name span,#gridProg .unePartie .img .name span{
      
    font-size:4vw; 
  
    }
}