@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

#logoHeader{
    height: 5vw;
    position: absolute;
    top: 1vw;
    left: 1vw;
    cursor: pointer;
}

.btnDrop{
    position:absolute;
    background-color: transparent;
    color:white;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    height: 3vw;

    font-size: 1.1vw;
    top: 1.5vw;
}

.btnDrop3{
    position:absolute;
    background-color: transparent;
    color:white;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    height: 6vw;

    font-size: 1.1vw;
    top: 1.5vw;
}


.btnPasDrop{
    position:absolute;
    background-color: transparent;
    color:white;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    height: 3vw;

    font-size: 1.1vw;
    top: 1.5vw;
}

#btnAccueil{
    left: 8.5vw;
}

#btnPhotos{
    left: 18vw;
}

#btnPropos{
    left: 27vw;
}

#btnInfos{
    left: 36vw;
}


#btnNosArtistes{
    left: 47vw;
}
#btnNosPartenaires{
    left: 58vw;
}
#btnPlaylists{
    left: 70vw;
}



#btnBilleterie{
    background-color: #ffA06A;
    border-radius: 2vw;
    color: white;
    cursor: pointer;
    font-weight: bold;
    padding: 0.5vw 1vw;
    text-align: center;
    transition: 200ms;
    width: 12vw;
    box-sizing: border-box;
    border: 0;
    font-size: 1.25vw;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    position: absolute;
    right: 2vw;
    top:2vw;
    cursor: pointer;
}
#header{
    position:absolute;
    height: 10vw;
    width: 100%;
    background-color: transparent;
}
.btnDrop{
    transition: 200ms;
    display:block;
    width:13.5vw;
    height:10vw;
}
.btnDrop:hover{
    transition:200ms;
    top:0px;
}

.btnDrop3:hover{
    transition:200ms;
    top:-10px;
}
.btnDrop .rotator {
    transition: 200ms;
    opacity: 1;
}
.btnDrop:hover .rotator {
    transition: 200ms;
    opacity: 0;
}

#header p{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight:400;
    font-size:1.25vw;
}


#header .dropContent a {
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1vw;
	width:7.5vw;
    display:block;
    margin: 0px;
    margin-left: 0.5vw;
    padding-bottom:0.5vw;
    border:0px;

    text-decoration: none;
    color:white;
    
}

.dropContent{
    display: block;
    opacity:0;
    position: absolute;
    width:7.5vw;
    transition:500ms;
    margin-left:2.5vw;

    
    
}
#header div:hover .dropContent {
    transition:500ms;
	display:block;
    opacity:1;
    border:0px;
    border-left: 0.1vw;
    border-style: solid;  
}
#header .btnDrop p{
    margin-bottom:0.5vw;
}

@media all and (orientation: portrait){

    #logoHeader{
        height: 14vw;
        position: absolute;
        top: 2vw;
        left: 2vw;
        cursor: pointer;
    }

    .btnDrop, .btnPasDrop{
        position:absolute;
        background-color: transparent;
        color:white;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        height: 6vw;
        top: 3vw;
    }

    #btnAccueil{
        visibility: hidden;
    }

    #btnPhotos{
        left: 18vw;
    }

    #btnPropos{
        left: 28vw;
    }

    #btnInfos{
        left: 40vw;
    }

    #btnPlaylists{
        visibility: hidden;
    }

    
#btnNosArtistes{
    left: 59vw;
}
#btnNosPartenaires{
    left: 75vw;
}


    #btnBilleterie{
        background-color: #ffA06A;
        border-radius: 4vw;
        color: white;
        cursor: pointer;
        font-weight: bold;
        padding: 2vw 2vw;
        text-align: center;
        transition: 200ms;
        width: 30vw;
        box-sizing: border-box;
        border: 0;
        font-size: 3.3vw;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;

        position: absolute;
        right: 4vw;
        top: 4.7vw;
        cursor: pointer;
    }
    #header{
        position:absolute;
        height: 20vw;
        width: 100%;
        background-color: transparent;
    }
    .btnDrop, .btnPasDrop{
        transition: 200ms;
        display:block;
        width:30vw;
        height:15vw;
    }
    .btnDrop3{
        height:20vw;
    }

    #header p{
        font-family: 'Roboto Condensed', sans-serif;
    font-weight:400;
        font-size:2.8vw;
    }


    #header .dropContent a {
        font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
        font-size:3vw;
        width:25vw;
        display:block;
        margin: 0px;
        margin-left: 0.5vw;
        padding-bottom:0.5vw;
        border:0px;

        text-decoration: none;
        color:white;

        pointer-events: none;
        display:none;
        
    }
    #header div:hover .dropContent a{
        pointer-events: all;
        display:block;
    }


    #header .dropContent{
        display: block;
        opacity:0;
        position: absolute;
        width:30vw;
        transition:500ms;
        margin-left:2.5vw;
        height: 100px;

        
        
    }
    #header div:hover .dropContent {
        transition:500ms;
        display:block;
        opacity:1;
        border:0px;
        border-left: 0.1vw;
        border-style: solid; 
    }
    #header .btnDrop p{
        margin-bottom:0.5vw;
    }

}
/*
@media only screen and (hover: none) and (pointer: coarse){
    #header .dropContent{
        display: none;
        opacity:0;
        position: absolute;
        width:10vw;
        transition:500ms;
        margin-left:2.5vw;
        pointer-events: none;
        
    }
    #header .dropContent a{
        display: none;
        pointer-events:none;
    }
    #header div:hover .dropContent a{
        display:block;
        pointer-events:all;
    }
    #header div:hover .dropContent {
        transition:500ms;
        display:block;
        opacity:1;
        border:0px;
        border-left: 0.1vw;
        border-style: solid; 
    }
    
}
*/