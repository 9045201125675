@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');

#imgDisc{
    display:block;
    top:0px;
    width:100%;
    height:100vh;
    object-fit:cover;
    left:0px;
    top:0px;

}
#headerEvenements{
    position:relative;
    float:left;
    width:100%;
    height:100%;
}
#headerEvenements p{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
    font-size:1.5vw;
    color:white;
    position :absolute;
    top:90%;
    left:50%;
    margin: auto;
    transform: translateX(-50%);
    text-align: center;
}
#headerEvenements span{
    
    font-size:2.5vw;
}
#evenementsWrapper{
    padding-top:2.5vw;
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5vw;
  grid-auto-rows: minmax(auto, auto);
  width: 100%;
  
  margin: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

#evenementsWrapper p{
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1.25vw;
}
#evenementsWrapper p span{
    font-size: 2.5vw;
}
#evenementsText{
    margin:5vw;
}
#evenementsText p{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1.25vw;
}
#evenementsHook p{
  margin-top:0vw;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1.6vw;
}
#evenementsHook{
  
  margin:auto;
  margin-top:0vw;
  text-align:center;
}

@media all and (orientation: portrait){
  #headerEvenements p{
    font-size: 4vw;

  }
  #headerEvenements span{
    font-size:6vw;
  }
  #evenementsText p{
    font-size: 4vw;
  }
  #evenementsWrapper p{
    font-size:2.75vw;
  }
  #evenementsWrapper p span{
    font-size:6vw;
  }
  #evenementsHook p{
    font-size:4vw;
  }
}