@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');

#imgHeaderMentions{
    display: block ;
    float:left;
    top:0px;

    height:7vw;
    width:100%;
    background-color: #535353;
}
#headerMentions{
    height:7vw;
    display: relative;
}
#mentions{
    position: relative;
    float:left;
    width: 100%;
}
#contentMentions p{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
    font-size:1vw;
    margin-left: 20%;
    margin-right: 20%;
    text-decoration: none;

}
#mentionsTitle{
    font-weight:700;
    font-size:2vw;
    margin-left: 0px;
    margin-right: 0px;
}
.mentionsSubtitle{
    font-weight:300;
    margin-left: 18%;
    margin-right: 18%;
    margin-top: 3.75vw;
}
#contentMentions .mentionsSTitle{
    font-weight:500;
    font-size:2vw;
    margin-left: 18%;
    margin-right: 18%;
    margin-top: 4.5vw;
}
#contentMentions{
    padding-left:2.5vw;
    padding-right:2.5vw;
    padding-bottom:25vw;   
}
@media all and (orientation: portrait){
    #imgHeaderMentions{
        height:18vw;
    }
    #headerMentions{
        height:18vw;
    }
    #contentMentions p{
        font-size:3vw;

        margin-left: 5%;
        margin-right: 5%;
    }

    #mentionsTitle{
        font-size:5vw;
    }
    #contentMentions .mentionsSTitle{
        font-weight:500;
        font-size:4.5vw;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: 7vw;
    }
}