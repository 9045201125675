@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');

p{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    font-size:1vw;
}
#retour{
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    position: absolute;

    font-size: 2vw;
    
}
#blur{
    filter:blur(0.15vw);  
    /* z-index:-1; */
    position:relative;


    height: fit-content;
    width: fit-content;  
}

#concert{
    z-index:1;
    position:absolute;
    margin:20%;
    margin-top:0%;
    height:5vw;
    width:60%;
    background-color: white;
    top: 7.5vw;


    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 0px;
    height: 25vw;
}


#concert div {
    z-index: 1;
    height:100%;
    top:0px;    
}
#infosConcert{
    position: relative;
    grid-column: 1;
    background-color: white;
    border-right-color: black;
    border-style: solid;

    border-width:0px;
    border-right-width: 0.1vw;
}
#infosGroupe{
    
    position:relative;
    
    grid-column: 2/4;
    background-color: lightgrey;

}

#date{

    font-size: 1.5vw;
    margin-top:0;
}
#date span{
    font-size: 2vw;
}
#lieu{
    text-decoration: underline;
    color:black;
}
h3{
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 300;
    font-size:1.25vw;
    margin-left: 1vw;
}.Partenariat{
    font-size: 1.5vw;   
    margin-left:1vw;
}

#billeterieConcert{
    position: relative;
    background-color: #ffA06A;
    border-radius: 2vw;
    color: white;
    cursor: pointer;
    font-weight: bold;
    padding: 0.5vw 1vw;
    text-align: center;
    transition: 200ms;
    width: 12vw;
    box-sizing: border-box;
    border: 0;
    font-size: 1.25vw;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;

    margin:auto;
    margin-top: 2.5vw;
    left:50%;
    transform: translateX(-50%);
}

#name{
    font-weight: 400;
    font-size: 2.25vw;
    margin-bottom: 20px;
}

#premierePartie{
    font-weight: 400;
    font-size: 1.3vw;
    margin-bottom: 0px;
    margin-top: 20px;
}

#namePremierePartie{
    font-weight: 400;
    font-size: 1.5vw;
    margin-bottom: 0px;
    margin-top: 0px;
}

#divPremierePartie{
    max-height: 100px;
}
#infosGroupe img{
    width:80%;
    display: block;
    margin: auto;
}
#infosGroupe .header{
    width:100%
}
#quoteConcert{
    font-size: 1.5vw;
    font-style: italic;
    margin-left : 10%;

    margin-right : 10%;
}
#descrConcert{
    font-size: 1.1vw;
    margin:3.5vw;
}

@media all and (orientation: portrait){
    p{
        font-size:3vw;
    }#retour{
        font-size: 6vw;
    }#concert{
        margin:10%;
        width:80%;
    }
    h3{
        font-size:3vw;
    }#name{
        font-weight: 400;
        font-size: 5vw;
        margin-top: 8vw;
    }#premierePartie{
        font-weight: 400;
        font-size: 3.5vw;
        margin-top: 8vw;
    }#namePremierePartie{
        font-weight: 400;
        font-size: 4vw;
        margin-top: 2vw;
    }#date{

        font-size: 5vw;
    }
    #date span{
        font-size: 6vw;
    }#concert .tarif{
        font-size: 4vw;   
    }#concert .Partenariat{
        font-size: 4.5vw;   
    }#lieu{
        font-size: 3.3vw;
    }#heure{
        font-size: 3vw;
    }#billeterieConcert{
        border-radius: 5vw;
        padding: 4vw 2vw;
        width: 24vw;
        font-size: 3vw;
        margin-top: 5vw;
        margin-bottom: 2vw;
    }#quoteConcert{
        font-size: 4vw;
    }
    #descrConcert{
        font-size: 3.5vw;
    }#blur{
        filter:blur(0.25vw);
    }
    
}