@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap');
#benevolesPage{
    float:left;
    position:relative;
    top:0px;
    margin:0px;
}
#contentBenevole{
    padding:0px;
    margin:0px;
}

#headerBenevoles{
    position:relative;
    float:left;
    width:100%;
    height: auto;
    top:0px;
    margin:0px;

}#headerBenevoles p {
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 700;
    font-size:1.5vw;
    top:17vw;
    text-align: center;
    width:100%;
    position:absolute;
    color:white;
    
}#headerBenevoles span{
    
    font-size:2.5vw;
}#imgBenevoles{
    margin:0vw;
    display:block;
    width:100vw;
    object-fit:cover;
    left:0px;
    top:0px;
    margin-bottom: 3vw;
    display:block;

    
}#benevoleTexte{
margin-top: 28vw;

  width:100vw;
  margin-bottom:5vw;
}#benevoleTexte .titre{
    text-align: center;
    line-height: 1.6vw;
}
#titre1{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-size: 2.2vw;
  margin-bottom:0vw;
  
}
#titre2{
    font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  line-height: 1em;
  font-size: 1.8vw;
  margin-top:0vw;
    margin-right:0vw;
   margin-left:0vw;
}#benevoleTexte hr{
    margin:2vw;
}#benevoleTexte h2{
    margin-left: 10vw;
    font-size:1.6vw;
}#benevoleTexte h3{
    margin-left: 30vw;
    margin-right: 3vw;
    font-size:1.5vw;
}#benevoleTexte p{
    margin-left:10vw;
    margin-right: 10vw;
    font-size:1.4vw;
}#finBenev{
    margin-top:7vw;
    margin-left:0vw;
    text-align: center;
}
#finBenev .finTitre{
    margin-top:5vw;
    margin-left:0vw;
    font-size:1.4vw;
}#finBenev .finTexte{
    margin-left:0vw;
    margin-right: 0vw;
    font-size:1.4vw;
    margin-bottom:5vw;
}


#benevoleButton {
  background: #5E5DF0;
  border-radius: 50vw;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 600;
  font-size: 1.8vw;
  line-height: 1.2vw;
  opacity: 1;
  outline: 0 solid transparent;
  padding: 1.3vw 2vw;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
  word-break: break-word;
  border: 0;
}

@media all and (orientation: portrait){
    #headerBenevoles p {
        font-size:4vw;
        top:13vw;
    }#headerBenevoles span{

        font-size:7vw;
    }
    #titre1{
      font-size: 5vw;
      margin-left: 3vw;
      margin-right: 3vw;
      line-height: 4vw;
    }
    #titre2{
      font-size: 4.7vw;
      vertical-align:sub;
    }#benevoleTexte h2{
        font-size:5vw;
    }#benevoleTexte h3{
        font-size:4.5vw;
    }#benevoleTexte p{
        font-size:4vw;
    }#finBenev .finTexte{
        margin-left:2vw;
        margin-right: 2vw;
        font-size:4vw;
    }#finBenev .finTitre{
        font-size:4vw;
    }
    
    
    #benevoleButton {
      font-size: 6vw;
      line-height: 1.5vw;
      padding: 5vw 5vw;
    }

}